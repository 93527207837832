import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { DateTime } from "luxon";

import { mapsLine, eventTime } from "../helpers";
import { Event } from "../types";

interface Props {
  event: Event;
}

export default function EventBox({ event }: Props) {
  let url = event.location.link;
  if (!url.startsWith("https://") && !url.startsWith("http://")) {
    url = "https://" + url;
  }

  const isoDate = DateTime.fromISO(event.startTime, {
    zone: "America/New_York",
  }).toISODate();

  return (
    <>
      <div className="card block">
        <div className="card-content">
          <div className="media">
            <div className="media-content">
              <div className="title is-4">
                {event.name}{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={url}
                  className="has-text-link has-text-weight-medium"
                >
                  <span className="icon">
                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                  </span>
                </a>
              </div>
              <div className="subtitle is-6">
                <div className="is-flex is-flex-direction-row is-flex-wrap-wrap">
                  <div>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={mapsLine(event.location)}
                      className="has-text-link has-text-weight-medium"
                    >
                      {event.location.name}
                    </a>
                  </div>
                  <div>
                    &nbsp;-{" "}
                    <time dateTime={isoDate}>
                      {eventTime(event.startTime, event.endTime)}
                    </time>{" "}
                    -{" "}
                  </div>
                  <div>&nbsp;{event.cost}</div>
                </div>
              </div>
            </div>
          </div>
          {event.description && (
            <div className="content">
              <p>{event.description}</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
