import type { GetStaticProps } from "next";
import Head from "next/head";
import { DateTime, Interval } from "luxon";

import { readCSV } from "./../lib/fileutil";
import EventDayBox from "../components/eventDay";
import { EventDay, CSVEvent } from "../types";
import { getDaysEvents } from "../helpers";

import styles from "../styles/Home.module.css";

const eventRange = (): Interval => {
  const weekStart = DateTime.now().setZone("America/New_York").startOf("week");
  const nextWeekStart = weekStart.plus({ week: 1 });

  return Interval.fromDateTimes(weekStart, nextWeekStart);
};

export const getStaticProps: GetStaticProps = async (context) => {
  const events = await readCSV<CSVEvent>("events.csv");
  const range = eventRange();

  return {
    props: { eventDays: getDaysEvents(events, range) },
  };
};

interface Props {
  eventDays: EventDay[];
}

const Home: React.FC<Props> = ({ eventDays }) => {
  return (
    <>
      <Head>
        <title>Fun Marion</title>
        <meta name="description" content="Fun events around Marion, NC" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      {eventDays.length ? (
        eventDays.map((eventDay) => (
          <EventDayBox key={eventDay.day} eventDay={eventDay} />
        ))
      ) : (
        <p>Nothing going on this week.</p>
      )}
    </>
  );
};

export default Home;
