import { DateTime, Interval } from "luxon";

import { Location, Event, EventDay, CSVEvent } from "../types";

export const dateDisplay = (date: string) => {
  return DateTime.fromISO(date, {
    locale: "en",
    zone: "America/New_York",
  }).toLocaleString({
    month: "long",
    day: "numeric",
    weekday: "long",
  });
};

export const mapsLine = (location: Location) => {
  const { name, addressLine1, addressLine2, city, state, postalCode } =
    location;

  const loc = [name, addressLine1, addressLine2, city, state, postalCode]
    .filter(Boolean)
    .map((part) => part.replace(" ", "+"))
    .join(",+");

  return `https://www.google.com/maps/search/${loc}`;
};

export const eventTime = (startDatetime: string, endDatetime: string) => {
  const start = DateTime.fromISO(startDatetime, {
    zone: "America/New_York",
  });

  if (endDatetime !== "") {
    const end = DateTime.fromISO(endDatetime, {
      zone: "America/New_York",
    });

    const { days = 0 } = end.diff(start, "days").toObject();

    if (days > 6) {
      return `${start.toFormat("t")} - ${end.toFormat("ff")}`;
    }
    if (start.day !== end.day) {
      return `${start.toFormat("t")} - ${end.toFormat("EEE t")}`;
    }

    return `${start.toFormat("t")} - ${end.toFormat("t")}`;
  }

  return start.toFormat("t");
};

export const getDaysEvents = (
  events: CSVEvent[],
  range: Interval
): EventDay[] => {
  const dayEvents: { [key: string]: Event[] } = {};

  events.forEach((event) => {
    const startTime = DateTime.fromFormat(
      `${event["Event Start Date"]} ${event["Start Time"]} America/New_York`,
      "M/d/yyyy h:m:s a z"
    ).toString();

    let endTime = "";
    if (
      event["Event End Date (if multi-day event)"] != "" &&
      event["End Time"] != ""
    ) {
      endTime = DateTime.fromFormat(
        `${event["Event End Date (if multi-day event)"]} ${event["End Time"]} America/New_York`,
        "M/d/yyyy h:m:s a z"
      ).toString();
    } else if (event["Event End Date (if multi-day event)"] != "") {
      endTime = DateTime.fromFormat(
        `${event["Event End Date (if multi-day event)"]} America/New_York`,
        "M/d/yyyy z"
      ).toString();
    } else if (event["End Time"] != "") {
      endTime = DateTime.fromFormat(
        `${event["Event Start Date"]} ${event["End Time"]} America/New_York`,
        "M/d/yyyy h:m:s a z"
      ).toString();
    }

    const e = {
      name: event["Name of Event"],
      startTime,
      endTime,
      location: {
        name: event["Name of Venue"],
        addressLine1: event["Street Address"],
        addressLine2: "",
        city: event.City,
        state: "NC",
        postalCode: "",
        link: event["Event Link"],
      },
      contact: {
        name: event["Contact Name"],
        email: event["Contact Email"],
        phone: event["Contact Phone Number"],
      },
      description: event["Description of Event (max. 600 characters)"],
      cost: event["What is the cost to attend this event?"],
    } as Event;

    const start = DateTime.fromISO(e.startTime, { zone: "America/New_York" });

    // the start time of the event is between the beginning of the week and the
    // end of the week
    if (range.contains(start)) {
      const startKey = start.toFormat("yyyy-MM-dd");

      if (!dayEvents[startKey]) {
        dayEvents[startKey] = [];
      }

      dayEvents[startKey].push(e);
    }
  });

  const finalDayEvents: EventDay[] = [];
  for (const key in dayEvents) {
    finalDayEvents.push({
      day: key,
      events: dayEvents[key],
    });
  }

  finalDayEvents.sort((a, b): number => {
    const aa = DateTime.fromISO(a.day);
    const bb = DateTime.fromISO(b.day);
    const { milliseconds = 0 } = aa.diff(bb).toObject();

    if (milliseconds >= 0) {
      return 1;
    }

    return -1;
  });

  return finalDayEvents;
};

export interface TemplateEventDay {
  day: string;
  events: TemplateEvent[];
}

interface TemplateEvent {
  name: string;
  cost: string;
  description: string;
  time: string;
  location: TemplateEventLocation;
}

interface TemplateEventLocation {
  name: string;
  link: string;
  maps: string;
}

export const eventDayToTemplateEventDay = (
  event: EventDay
): TemplateEventDay => {
  return {
    day: dateDisplay(event.day),
    events: event.events.map((e) => {
      const {
        name,
        cost,
        description,
        startTime,
        endTime,
        location: { link, name: locationName },
      } = e;

      return {
        name,
        cost,
        description,
        time: eventTime(startTime, endTime),
        location: {
          name: locationName,
          link,
          maps: mapsLine(e.location),
        },
      };
    }),
  };
};
