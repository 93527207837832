import { DateTime } from "luxon";

import { dateDisplay } from "../helpers";
import EventBox from "./event";
import { EventDay } from "../types";

interface Props {
  eventDay: EventDay;
}

export default function EventDayBox({ eventDay }: Props) {
  return (
    <section className="section">
      <h1 className="title">{dateDisplay(eventDay.day)}</h1>
      {eventDay.events
        .sort((a, b): number => {
          const startA = DateTime.fromISO(a.startTime);
          const startB = DateTime.fromISO(b.startTime);
          const { milliseconds: startMilliseconds = 0 } = startA
            .diff(startB)
            .toObject();

          if (startMilliseconds > 0) {
            return 1;
          } else if (startMilliseconds < 0) {
            return -1;
          }

          const endA = DateTime.fromISO(a.endTime);
          const endB = DateTime.fromISO(b.endTime);
          const { milliseconds: endMilliseconds = 0 } = endA
            .diff(endB)
            .toObject();

          if (endMilliseconds >= 0) {
            return 1;
          }

          return -1;
        })
        .map((event, i) => (
          <EventBox key={i} event={event} />
        ))}
    </section>
  );
}
